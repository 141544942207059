import { configureStore, createSlice } from '@reduxjs/toolkit';

import GeoRegion from 'services/GeoRegion';

const visitedRegionsSlice = createSlice({

    name: 'visitedRegions',
    initialState: { visitedRegions: [] },

    reducers: {
        addRegion: (state, action) => {

            const { visitedRegions } = state;
            const newLastRegion = action.payload;

            if (newLastRegion.id !== GeoRegion.COUNTRY_ID) {

                const currentLastRegion = visitedRegions.length && visitedRegions[visitedRegions.length - 1];

                if (!currentLastRegion || currentLastRegion.id !== newLastRegion.id) {
                    visitedRegions.push(newLastRegion);                
                }
            }
        },
        removeLastRegion: (state) => {
            state.visitedRegions = state.visitedRegions.slice(0, state.visitedRegions.length - 1);
        }
    }
});

const loadingControlSlice = createSlice({

    name: 'loadingControl',
    initialState: { loadingInProgress: true },

    reducers: {
        loadingFinished: (state) => {
            state.loadingInProgress = false;
        }
    }
});

export const { addRegion, removeLastRegion } = visitedRegionsSlice.actions;
export const { loadingFinished } = loadingControlSlice.actions;

const store = configureStore({
    reducer: {
        visitedRegions: visitedRegionsSlice.reducer,
        loadingControl: loadingControlSlice.reducer,
    },
});

export default store;
