import axios from 'axios';

class RegionDirectionsService {

    constructor(regionDirectionsApiUrl) {
        this.regionDirectionsApiUrl = regionDirectionsApiUrl;
    }

    async getRegionRoutes(regionId) {
        const getUrl = `${this.regionDirectionsApiUrl}/public/region/${regionId}/routes`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }

    async getRouteDetails(routeId) {
        const getUrl = `${this.regionDirectionsApiUrl}/public/route/${routeId}`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }
};

export default RegionDirectionsService;