import GeoRegion from 'services/GeoRegion';

const buildRegionLink = (voivodeshipRefName, countyRefName, communityRefName, cityRefName) => {

    const level = GeoRegion.getRegionLevel(voivodeshipRefName, countyRefName, communityRefName, cityRefName);

    const links = {
        [GeoRegion.LEVEL.COUNTRY]: `/`,
        [GeoRegion.LEVEL.VOIVODESHIP]: `/region/${voivodeshipRefName}`,
        [GeoRegion.LEVEL.COUNTY]: `/region/${voivodeshipRefName}/${countyRefName}`,
        [GeoRegion.LEVEL.COMMUNITY]: `/region/${voivodeshipRefName}/${countyRefName}/${communityRefName}`,
        [GeoRegion.LEVEL.CITY]: `/region/${voivodeshipRefName}/${countyRefName}/${communityRefName}/${cityRefName}`,
    };
    const link = links[level];
    if (link) {
        return link;
    }
    
    throw new Error(`Region level not supported: ${level}`);
};

export default buildRegionLink;
