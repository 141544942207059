import PropTypes from 'prop-types';

import { styled, alpha } from '@mui/material/styles';
import { Toolbar, OutlinedInput, InputAdornment } from '@mui/material';

import Iconify from 'components-material-kit/iconify';

const StyledRoot = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    "&": {
        paddingLeft: 0,
    }
});

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    borderRadius: theme.spacing(3),
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
    input: {
        padding: theme.spacing(1, 0, 1, 0),
        fontSize: '0.8125rem',
    },
}));

const SearchToolbar = ({
    placeholderText, filterValue, onFilterValueChange, onBlur,
    maxCharacters = 50, inputElementWidth = '100%', inputElementFocusedWidth = '100%',
}) => (
    <StyledRoot>
        <StyledSearch
            sx={{
                width: inputElementWidth,
                '&.Mui-focused': { width: inputElementFocusedWidth },
            }}
            value={filterValue}
            onChange={onFilterValueChange}
            placeholder={placeholderText}
            onBlur={onBlur}
            inputProps={{ maxLength: maxCharacters }}
            startAdornment={
                <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
            }
        />
    </StyledRoot>
);

SearchToolbar.propTypes = {
    placeholderText: PropTypes.string,
    filterValue: PropTypes.string,
    onFilterValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    maxCharacters: PropTypes.number,
    inputElementWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputElementFocusedWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SearchToolbar;