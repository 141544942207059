import React from 'react';

import { Link } from 'react-router-dom';

import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import { useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import SearchPanel from 'components/SearchPanel';
import servicesContext from 'servicesContext';


import AboutMenu from './AboutMenu';

const { administrativeUnitsService } = servicesContext;

const TopAppBar = () => {

    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <AppBar
            position="static"
            sx={{
                borderBottom: `1px solid ${theme.palette.primary.light}`,
                backgroundColor: 'white',
                boxShadow: 'none',
            }}
            className="noprint"
        >
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{ pt: 2, display: {xs: 'none', md: 'block' }, width: { xs: '0px', md: '380px'} }}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: (theme) => theme.palette.grey[700], textWrap: 'nowrap' }}
                        >
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: t('topBar.serviceTitle') }} />
                        </Typography>
                    </Link>                    
                </Box>
                <Box sx={{ pt: 2, display: {xs: 'block', md: 'none' } }}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <HomeIcon sx={{ color: (theme) => theme.palette.grey[700], fontSize: 24 }} />
                    </Link>                    
                </Box>
                <Box sx={{ px: 2, width: { xs: '100%', md: '380px' } }}>
                    <SearchPanel administrativeUnitsService={administrativeUnitsService} />        
                </Box>
                <Box sx={{ display: {xs: 'none', md: 'flex' }, pt: 2, maxWidth: { xs: '0px', md: '380px'} }}>
                    <AboutMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default TopAppBar;
