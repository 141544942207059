class GeoRegion {
    
    static COUNTRY_ID = process.env.REACT_APP_ADMINISTRATIVE_UNITS_COUNTRY_ID;
    
    static COUNTRY_REF_PATH = process.env.REACT_APP_ADMINISTRATIVE_UNITS_COUNTRY_REF_PATH;
    
    static LEVEL = {
        COUNTRY: 2,
        VOIVODESHIP: 4,
        COUNTY: 6,
        COMMUNITY: 7,
        CITY: 8,
    }

    static SMALL_CITY_POPULATION_MIN = 15000;

    static BIG_CITY_POPULATION_MIN = 50000;

    static SMALL_CITY_MAX_DISTANCE = 25000;
    
    static BIG_CITY_MAX_DISTANCE = 50000;

    static getRegionLevel(voivodeshipRefName, countyRefName, communityRefName, cityRefName) {
        if (cityRefName) {
            return GeoRegion.LEVEL.CITY;
        }
        if (communityRefName) {
            return GeoRegion.LEVEL.COMMUNITY;
        }
        if (countyRefName) {
            return GeoRegion.LEVEL.COUNTY;
        }
        if (voivodeshipRefName) {
            return GeoRegion.LEVEL.VOIVODESHIP;
        }
        
        return GeoRegion.LEVEL.COUNTRY;
    }

    static buildRefPath(voivodeshipRefName, countyRefName, communityRefName, cityRefName) {

        const pathSegments = [];
        const pushPathSegment = (segment) => {
            if (segment) {
                pathSegments.push(segment);
            }
        }
        pushPathSegment(voivodeshipRefName);
        pushPathSegment(countyRefName);
        pushPathSegment(communityRefName);
        pushPathSegment(cityRefName);

        if (pathSegments.length) {
            return pathSegments.join('$');
        }
        
        return GeoRegion.COUNTRY_REF_PATH;
    }
};

export default GeoRegion;
