import teaserTexts from './RegionTeaser/texts';

const texts = {
    "regionStatsTiles": "Liczba mieszkanców, powierzchnia i gęstość zaludnienia dla {{regionType}} {{region}} w roku {{year}}.<br/>Liczba mieszkanców podana wg {{sourceType}}.",
    "regionStatsTiles.regionType.region": "regionu",
    "regionStatsTiles.regionType.city": "miejscowości",
    "regionStatsTiles.sourceType.bdl": "<a href='https://bdl.stat.gov.pl/' target='_blank'>Bazy Danych Lokalnych</a>",
    "regionStatsTiles.sourceType.worldpop": "<a href='https://www.worldpop.org/' target='_blank'>WorldPop</a> skorygowana danymi z <a href='https://bdl.stat.gov.pl/' target='_blank'>Bazy Danych Lokalnych</a>",
    "regionPopulationTrend": "Zmiany liczby mieszkanców dla {{regionType}} {{region}} w latach {{minYear}} - {{maxYear}}. Liczba mieszkanców {{populationChangeType}} w tych latach o {{populationChange}}%.",
    "regionPopulationTrend.regionType.region": "regionu",
    "regionPopulationTrend.regionType.city": "miejscowości",
    "regionPopulationTrend.changeType.increased": "wzrosła",
    "regionPopulationTrend.changeType.decreased": "spadła",
    "regionPopulationRanking": "Ranking {{regionType}} w regionie {{parentRegion}} pod względem liczby mieszkanców. Ranking został utworzony dla roku {{year}}. {{regionType2}} {{region}} znajduje się na {{rank}} miejscu w zestawieniu.",
    "regionPopulationRanking.regionType.county": "powiatów",
    "regionPopulationRanking.regionType.community": "gmin",
    "regionPopulationRanking.regionType.city": "miejscowości",    
    "regionPopulationRanking.regionType2.region": "Region",
    "regionPopulationRanking.regionType2.city": "Miejscowość",
    "regionAreaRanking": "Ranking {{regionType}} w regionie {{parentRegion}} pod względem powierzchni. {{regionType2}} {{region}} znajduje się na {{rank}} miejscu w zestawieniu.",
    "regionAreaRanking.regionType.county": "powiatów",
    "regionAreaRanking.regionType.community": "gmin",
    "regionAreaRanking.regionType.city": "miejscowości",    
    "regionAreaRanking.regionType2.region": "Region",
    "regionAreaRanking.regionType2.city": "Miejscowość",
    "regionDensityRanking": "Ranking {{regionType}} w regionie {{parentRegion}} pod względem gęstości zaludnienia. Ranking został utworzony dla roku {{year}}. {{regionType2}} {{region}} znajduje się na {{rank}} miejscu w zestawieniu.",
    "regionDensityRanking.regionType.county": "powiatów",
    "regionDensityRanking.regionType.community": "gmin",
    "regionDensityRanking.regionType.city": "miejscowości",
    "regionDensityRanking.regionType2.region": "Region",
    "regionDensityRanking.regionType2.city": "Miejscowość",
    ...teaserTexts,
}

export default texts;
