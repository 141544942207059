import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import {
    Box,
    Card,
    Table,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import buildRegionLink from 'routes/buildRegionLink';

import Scrollbar from 'components-material-kit/scrollbar';

import SearchToolbar from './SearchToolbar';

const buildRegionItems = (regions) => {

    const names = regions.map(({ name }) => name);
    const duplicateNames = names.filter((item, index) => names.indexOf(item) !== index);

    const buildDuplicateLabel = (region) => {
        const directParent = region.parents[0];
        return `${region.name} (${directParent.name})`;
    }
    const labels = regions.map(
        (region) => ({
            id: region.id,
            name: duplicateNames.includes(region.name) ? buildDuplicateLabel(region) : region.name,
            link: buildRegionLink(
                ...region.parents.reverse().slice(1).map((region) => region.refName), 
                region.refName,
            ),
        })
    );
    return labels;
};

SearchPanel.propTypes = {
    administrativeUnitsService: PropTypes.object,
};

export default function SearchPanel({ administrativeUnitsService }) {

    const { t } = useTranslation();

    const [filterName, setFilterName] = useState('');
    const [filteredRegions, setFilteredRegions] = useState([]);
    const [searchTimeoutId, setSearchTimeoutId] = useState(null);

    useEffect(() => {

        const fetch = async () => {
            if (filterName) {
                const regions = await administrativeUnitsService.searchAdministrativeUnits(filterName);
                setFilteredRegions(buildRegionItems(regions));    
            } else {
                setFilteredRegions([]);
            }
        };

        clearTimeout(searchTimeoutId);
        const timeoutId = setTimeout(() => fetch(), 300);
        setSearchTimeoutId(timeoutId);
        
    }, [filterName]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const cleanUpFilterName = () => {
        clearTimeout(searchTimeoutId);
        setFilterName('');
    };

    const isNotFound = !filteredRegions.length && !!filterName;

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            
            <SearchToolbar
                placeholderText={t('searchBar.searchCityOrRegion')}
                filterValue={filterName}
                onFilterValueChange={handleFilterByName}
                onBlur={cleanUpFilterName}
                inputElementWidth={270}
                inputElementFocusedWidth={340}
            />

            {filterName &&                    
                <Card sx={{ mb: 2, minWidth: 400  }} >
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 400 }}>
                            <Table>

                                <TableBody>
                                    {filteredRegions.map((row) => {
                                        const { id, name, link } = row;

                                        return (
                                            <TableRow hover key={id} tabIndex={-1}>
                                                <TableCell align="left">
                                                    {/* onMouseDown prevents premature onBlur in SearchToolbar */} 
                                                    <NavLink
                                                        to={link}
                                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                                        onClick={cleanUpFilterName}
                                                        onMouseDown={event => event.preventDefault()}
                                                    >
                                                        <Box sx={{ pl: 2 }}>{name}</Box>
                                                    </NavLink>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="body2">
                                                        {t('searchBar.noResults')}
                                                        &nbsp;<strong>&quot;{filterName}&quot;</strong>
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            }
        </Container>
    );
}