import 'mapbox-gl/dist/mapbox-gl.css';

import { Provider as ReduxProvider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import store from './store/store';
import Router from './routes';
import ThemeProvider from './theme';

import servicesContext from './servicesContext';
import { ServicesContext } from './context';

export default function App() {
    return (
        <ReduxProvider store={store}>
            <ServicesContext.Provider value={servicesContext}>
                <HelmetProvider>
                    <BrowserRouter>
                        <ThemeProvider>
                            <Router />
                        </ThemeProvider>
                    </BrowserRouter>
                </HelmetProvider>
            </ServicesContext.Provider>
        </ReduxProvider>
    );
}
