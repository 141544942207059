import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from './translations.json';
import regionPageTexts from './pages/RegionPage/texts';

const resources = {
    pl: {
        translation: { ...translation, ...regionPageTexts },
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'pl',
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        }
    });

export default i18n;