import axios from 'axios';

class AdministrativeUnitsService {

    constructor(administrativeUnitsApiUrl, countryRefPath) {
        this.administrativeUnitsApiUrl = administrativeUnitsApiUrl;
        this.countryRefPath = countryRefPath;
    }

    async getAdmistrativeUnitsByParentId(parentId) {
        const getUrl = `${this.administrativeUnitsApiUrl}/public/region/${parentId}/children`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }

    async getAdmistrativeUnitById(unitId) {
        const getUrl = `${this.administrativeUnitsApiUrl}/public/region/${unitId}`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }

    async searchAdministrativeUnits(query) {
        const getUrl = `${this.administrativeUnitsApiUrl}/public/region/`;
        const { data: content } = await axios.get(getUrl, { params: { query, includeParents: true } });
        return content.data;
    }
};

export default AdministrativeUnitsService;