/* eslint-disable import/no-webpack-loader-syntax */
const countryIntro = require('!!raw-loader!./country.intro.html');
const countryUnits = require('!!raw-loader!./country.units.html');

const countryTeaserTexts = {
    'countryTeaser.country': 'Polska',
    'countryTeaser.intro': countryIntro.default,
    'countryTeaser.units': countryUnits.default,
    'countryTeaser.populationChangeType.increase': 'wzrosła',
    'countryTeaser.populationChangeType.decrease': 'spadła',
};

export default countryTeaserTexts;
