import axios from 'axios';

import GeoRegion from './GeoRegion';

class RegionInsightsPortalService {

    constructor(regionInsightsApiUrl, administrativeUnitsService) {
        this.regionInsightsApiUrl = regionInsightsApiUrl;
        this.administrativeUnitsService = administrativeUnitsService;
    }

    async getRegionBasics(unitRefPath) {

        if (unitRefPath !== GeoRegion.COUNTRY_REF_PATH) {
            const getUrl = `${this.regionInsightsApiUrl}/public/region/ref-path/${unitRefPath}/page/basics`;
            const { data: content } = await axios.get(getUrl);
            return content.data;    
        }
        
        const region = {
            id: GeoRegion.COUNTRY_ID,
            refPath: GeoRegion.COUNTRY_REF_PATH,
            name: 'Polska',
            regionCenters: [],
        };
        const children = await this.administrativeUnitsService.getAdmistrativeUnitsByParentId(
            region.id,
        );
        return { region, children, parents: [] };
    }

    async getRegionDetails(unitRefPath) {
        const getUrl = `${this.regionInsightsApiUrl}/public/region/ref-path/${unitRefPath}/page/details`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }
};

export default RegionInsightsPortalService;